import React from 'react';
import PropTypes from 'prop-types';

import { getIcons } from 'utils/contentProvider';

const Icon = ({
  name,
  width,
  height,
  fill,
  rotate,
  title,
  verticalAlign,
  style,
  ...rest
}) => {
  let current = getIcons().find((i) => i.name === name);

  // Set the icon to 'warning' if not found.
  current =
    current === undefined
      ? getIcons().find((i) => i.name === 'warning')
      : current;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || current.width}
      height={height || current.height}
      viewBox={current.viewBox}
      fill={fill || current.fill}
      style={{
        transform: rotate ? `rotate(${rotate})` : '',
        verticalAlign: verticalAlign || 'baseline',
        ...style,
      }}
      title={title}
      {...rest}>
      {current.svg}
    </svg>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  rotate: PropTypes.string,
  title: PropTypes.string,
  verticalAlign: PropTypes.string,
  style: PropTypes.object,
};

export default Icon;
