import React from 'react';

export const getIcons = () => [
  {
    id: 1,
    name: 'warning',
    svg: (
      <>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
      </>
    ),
    viewBox: '0 0 24 24',
    height: '24px',
    width: '24px',
  },
  {
    id: 2,
    name: 'facebook',
    svg: (
      <path d="M72.089.02L59.624 0C45.62 0 36.57 9.285 36.57 23.656v10.907H24.037a1.96 1.96 0 00-1.96 1.961v15.803a1.96 1.96 0 001.96 1.96H36.57v39.876a1.96 1.96 0 001.96 1.96h16.352a1.96 1.96 0 001.96-1.96V54.287h14.654a1.96 1.96 0 001.96-1.96l.006-15.803a1.963 1.963 0 00-1.961-1.961H56.842v-9.246c0-4.444 1.059-6.7 6.848-6.7l8.397-.003a1.96 1.96 0 001.959-1.96V1.98A1.96 1.96 0 0072.089.02z" />
    ),
    viewBox: '0 0 96.124 96.124',
    height: '24px',
    width: '24px',
  },
  {
    id: 3,
    name: 'twitter',
    svg: (
      <path d="M512.002 97.211c-18.84 8.354-39.082 14.001-60.33 16.54 21.686-13 38.342-33.585 46.186-58.115a210.29 210.29 0 01-66.705 25.49c-19.16-20.415-46.461-33.17-76.674-33.17-58.011 0-105.042 47.029-105.042 105.039 0 8.233.929 16.25 2.72 23.939-87.3-4.382-164.701-46.2-216.509-109.753-9.042 15.514-14.223 33.558-14.223 52.809 0 36.444 18.544 68.596 46.73 87.433a104.614 104.614 0 01-47.577-13.139c-.01.438-.01.878-.01 1.321 0 50.894 36.209 93.348 84.261 103a105.245 105.245 0 01-27.674 3.687c-6.769 0-13.349-.66-19.764-1.888 13.368 41.73 52.16 72.104 98.126 72.949-35.95 28.176-81.243 44.967-130.458 44.967-8.479 0-16.84-.496-25.058-1.471 46.486 29.807 101.701 47.197 161.021 47.197 193.211 0 298.868-160.062 298.868-298.872 0-4.554-.104-9.084-.305-13.59 20.526-14.809 38.335-33.309 52.417-54.373z" />
    ),
    viewBox: '0 0 512.002 512.002',
    height: '24px',
    width: '24px',
  },
  {
    id: 4,
    name: 'youtube',
    svg: (
      <path d="M35.705 0s-22.35 0-27.94 1.47c-3 .824-5.47 3.295-6.294 6.354C0 13.412 0 25 0 25s0 11.646 1.47 17.176a8.95 8.95 0 006.296 6.295c5.647 1.53 27.94 1.53 27.94 1.53s22.352 0 27.94-1.47a8.95 8.95 0 006.296-6.297c1.47-5.588 1.47-17.175 1.47-17.175s.06-11.647-1.47-17.235a8.95 8.95 0 00-6.295-6.295C58.058 0 35.707 0 35.707 0zm-7.117 14.295L47.176 25 28.588 35.705v-21.41z" />
    ),
    viewBox: '0 0 71.412 50',
    height: '24px',
    width: '24px',
  },
  {
    id: 5,
    name: 'instagram',
    svg: (
      <>
        <path d="M352 0H160C71.648 0 0 71.648 0 160v192c0 88.352 71.648 160 160 160h192c88.352 0 160-71.648 160-160V160C512 71.648 440.352 0 352 0zm112 352c0 61.76-50.24 112-112 112H160c-61.76 0-112-50.24-112-112V160C48 98.24 98.24 48 160 48h192c61.76 0 112 50.24 112 112v192z" />
        <path d="M256 128c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z" />
        <circle cx="393.6" cy="118.4" r="17.056" />
      </>
    ),
    viewBox: '0 0 512 512',
    height: '24px',
    width: '24px',
  },
  {
    id: 6,
    name: 'linkedin',
    svg: (
      <path d="M430.117 261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707-25.473 0-40.632 17.142-47.301 33.724-2.432 5.928-3.058 14.179-3.058 22.477V420.56h-92.219s1.242-251.285 0-277.32h92.21v39.309c-.187.294-.43.611-.606.896h.606v-.896c12.251-18.869 34.13-45.824 83.102-45.824 60.673-.001 106.157 39.636 106.157 124.818zM52.183 9.558C20.635 9.558 0 30.251 0 57.463c0 26.619 20.038 47.94 50.959 47.94h.616c32.159 0 52.159-21.317 52.159-47.94-.606-27.212-20-47.905-51.551-47.905zM5.477 420.56h92.184V143.24H5.477v277.32z" />
    ),
    viewBox: '0 0 430.117 430.117',
    height: '24px',
    width: '24px',
  },
];
