import styled from 'styled-components';

import theme from 'styles/theme';

export default styled.nav`
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 15px 0;
  box-shadow: 0 0.8px 1.2px rgba(0, 0, 0, 0.03),
    0 1.9px 3px rgba(0, 0, 0, 0.022), 0 3.6px 5.6px rgba(0, 0, 0, 0.018),
    0 6.5px 10.1px rgba(0, 0, 0, 0.015), 0 12.1px 18.8px rgba(0, 0, 0, 0.012),
    0 29px 45px rgba(0, 0, 0, 0.008);

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 10px;
      padding: 0;

      a {
        svg {
          fill: ${theme.colors.black};
        }

        &:hover {
          svg {
            fill: ${theme.colors.blue};
          }
        }
      }
    }

    &.header-menu-links {
      margin: 0 -15px;

      li {
        margin: 0 15px;
      }
    }

    &.header-social-links {
      margin: 0 -10px;
    }
  }
`;
