import React from 'react';

import Container from 'components/Container';

import Main from './Footer.styled';

const Footer = () => (
  <Main>
    <Container>
      <div>
        <span>
          &copy;&nbsp;
          {new Date().getFullYear()}
          &nbsp;Facticular
        </span>
        <span> | </span>
        <a href="mailto:hello@facticular.com">Contact us</a>
      </div>
      {/* <Row align="flex-start">
        <Column>
          <h4>Information</h4>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about/">About</Link>
            </li>
            <li>
              <Link to="/contact/">Contact</Link>
            </li>
          </ul>
        </Column>
        <Column flex="2">
          <h4>Contact us</h4>
          <a href="mailto:hello@facticular.com">hello@facticular.com</a>
        </Column>
        <Column>
          <h4>Subscribe to our mailing list</h4>
          <span>
            &copy;&nbsp;
            {new Date().getFullYear()}
            &nbsp;Facticular
          </span>
        </Column>
      </Row> */}
    </Container>
  </Main>
);

export default Footer;
