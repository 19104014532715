import { createGlobalStyle } from 'styled-components';

import fonts from './fonts';

export default createGlobalStyle`
  ${fonts}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    font-family: 'Cerebri', sans-serif;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  img,
  video {
    max-width: 100%;
    vertical-align: middle;
  }

  a {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.blue};
    transition: color 0.2s;

    svg {
      fill: ${({ theme: { colors } }) => colors.blue};
      transition: fill 0.2s;
    }

    &:hover {
      color: ${({ theme: { colors } }) => colors.blueHover};
      
      svg {
        fill: ${({ theme: { colors } }) => colors.blueHover};
      }
    }
    
    &:focus {
      text-decoration: underline;
    }
  }

  .center {
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .blue {
    color: ${({ theme: { colors } }) => colors.blue};
  }
`;
