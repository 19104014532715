export default `
  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-light.eot');
    src:url('/assets/fonts/cerebrisans-light.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-light.woff') format('woff'),
      url('/assets/fonts/cerebrisans-light.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-light.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-light.svg#cerebrisans-light') format('svg');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-lightitalic.eot');
    src:url('/assets/fonts/cerebrisans-lightitalic.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-lightitalic.woff') format('woff'),
      url('/assets/fonts/cerebrisans-lightitalic.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-lightitalic.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-lightitalic.svg#cerebrisans-lightitalic') format('svg');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-regular.eot');
    src:url('/assets/fonts/cerebrisans-regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-regular.woff') format('woff'),
      url('/assets/fonts/cerebrisans-regular.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-regular.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-regular.svg#cerebrisans-regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-italic.eot');
    src:url('/assets/fonts/cerebrisans-italic.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-italic.woff') format('woff'),
      url('/assets/fonts/cerebrisans-italic.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-italic.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-italic.svg#cerebrisans-italic') format('svg');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-medium.eot');
    src:url('/assets/fonts/cerebrisans-medium.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-medium.woff') format('woff'),
      url('/assets/fonts/cerebrisans-medium.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-medium.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-medium.svg#cerebrisans-medium') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-mediumitalic.eot');
    src:url('/assets/fonts/cerebrisans-mediumitalic.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-mediumitalic.woff') format('woff'),
      url('/assets/fonts/cerebrisans-mediumitalic.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-mediumitalic.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-mediumitalic.svg#cerebrisans-mediumitalic') format('svg');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-bold.eot');
    src:url('/assets/fonts/cerebrisans-bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-bold.woff') format('woff'),
      url('/assets/fonts/cerebrisans-bold.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-bold.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-bold.svg#cerebrisans-bold') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-bolditalic.eot');
    src:url('/assets/fonts/cerebrisans-bolditalic.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-bolditalic.woff') format('woff'),
      url('/assets/fonts/cerebrisans-bolditalic.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-bolditalic.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-bolditalic.svg#cerebrisans-bolditalic') format('svg');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-extrabold.eot');
    src:url('/assets/fonts/cerebrisans-extrabold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-extrabold.woff') format('woff'),
      url('/assets/fonts/cerebrisans-extrabold.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-extrabold.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-extrabold.svg#cerebrisans-extrabold') format('svg');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cerebri';
    src:url('/assets/fonts/cerebrisans-extrabolditalic.eot');
    src:url('/assets/fonts/cerebrisans-extrabolditalic.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/cerebrisans-extrabolditalic.woff') format('woff'),
      url('/assets/fonts/cerebrisans-extrabolditalic.woff2') format('woff'),
      url('/assets/fonts/cerebrisans-extrabolditalic.ttf') format('truetype'),
      url('/assets/fonts/cerebrisans-extrabolditalic.svg#cerebrisans-extrabolditalic') format('svg');
    font-weight: 800;
    font-style: italic;
  }
`;
