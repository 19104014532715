import styled from 'styled-components';

export default styled.footer`
  padding: 30px 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 10px 0;
      padding: 0;
    }
  }
`;
