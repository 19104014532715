import styled from 'styled-components';

export default styled.div`
  max-width: ${({ isContent }) => (isContent ? '950px' : '1200px')};
  margin: 0 auto;
  width: 100%;

  @media (max-width: ${({ isContent }) => (isContent ? '950px' : '1200px')}) {
    padding: 0 3%;
  }
`;
