import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-wrap: ${({ wrap }) => wrap || 'wrap'};
  justify-content: ${({ justify }) => justify || 'center'};
  align-items: ${({ align }) => align || 'center'};
  min-height: ${({ height }) => height || 'auto'};
  margin: 0 -15px;

  ${({ mobileColumn }) =>
    mobileColumn &&
    `
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `};
`;

export default Row;
