import React from 'react';
import PropTypes from 'prop-types';

import Header from 'components/Header';
import Footer from 'components/Footer';

import GlobalStyle from 'styles/globalStyles';
import theme from 'styles/theme';

const Layout = ({ location, children }) => (
  <>
    <GlobalStyle theme={theme} />
    <Header location={location} />
    <main>{children}</main>
    <Footer />
  </>
);

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
