import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Container from 'components/Container';
import Row from 'components/Row';
import Icon from 'components/Icon';

import Navigation from './Header.styled';

const Header = ({ location }) => {
  const categories = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/categories//" } }
      ) {
        nodes {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  `);

  useEffect(() => {
    document.getElementById('header-placeholder').style.height = `${
      document.getElementById('nav').getBoundingClientRect().height
    }px`;
  }, []);

  return (
    <>
      <Navigation id="nav">
        <Container>
          <Row justify="space-between" style={{ margin: 0 }}>
            <Link to="/">
              <img
                src="/assets/img/facticular-logo-dark.svg"
                width="120px"
                alt="facticular logo"
              />
            </Link>
            <ul className="header-menu-links" style={{ display: 'none' }}>
              {categories.allMarkdownRemark.nodes.map((i) => (
                <li key={i.frontmatter.slug}>
                  <a href={`/category/${i.frontmatter.slug}/`}>
                    {i.frontmatter.title}
                  </a>
                </li>
              ))}
              <li>
                <Link
                  to="/about/"
                  style={{
                    textDecoration:
                      location.pathname === '/about/' ? 'underline' : 'none',
                  }}>
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/contact/"
                  style={{
                    textDecoration:
                      location.pathname === '/contact/' ? 'underline' : 'none',
                  }}>
                  Contact
                </Link>
              </li>
            </ul>
            <ul className="header-social-links">
              {/* <li>
                <a href="#!" target="_blank" rel="noopener noreferrer">
                  <Icon name="facebook" height="18px" width="18px" />
                </a>
              </li> */}
              <li>
                <a
                  href="https://www.instagram.com/facticular/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Icon name="instagram" height="18px" width="18px" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/facticular/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Icon name="twitter" height="18px" width="18px" />
                </a>
              </li>
            </ul>
          </Row>
        </Container>
      </Navigation>
      <div id="header-placeholder" style={{ height: 53 }} />
    </>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Header;
